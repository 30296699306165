<template>
  <div>
    <b-card class="mt-0 px-2">
      <b-row
        class="mb-3 border-bottom"
      >
        <b-col cols="6">
          <div>
            <feather-icon
              icon="ListIcon"
              size="20"
              class="text-primary"
            /> <span
              class="text-primary"
              style="font-size:17px"
            ><strong>FORMULARIOS</strong></span>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-button
          variant="outline-success"
          @click="nuevoFormulario"
        >
          Nuevo formulario
        </b-button>
      </b-row>

      <b-table
        id="my-table"
        :no-provider-filtering="true"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="items"
        :fields="fields"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>

        <template #cell(acciones)="row">
          <b-button
            variant="outline-success"
            size="sm"
            @click="verFormulario(row.item)"
          >cargar formulario
          </b-button>
        </template>
      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BRow,
    BCol,
    BButton,

  },
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'codigo', label: 'CÓDIGO', sortable: true },
        { key: 'nombre', label: 'NOMBRE', sortable: true },
        { key: 'acciones' },
      ],
    }
  },
  mounted() {
    this.$http.get('/recursos/formulario').then(res => {
      this.items = res.data
    })
  },
  methods: {

    verFormulario(item) {
      if (item.autentificacion === 0) {
        this.$router.push({ name: 'vista-anonima-x', params: { id_formulariox: item.id } })
        return
      }
      this.$router.push({ name: 'formulario-x', params: { id_formulariox: item.id } })
    },
    nuevoFormulario() {
      const DATA = [
        {
          nombre: 'FORMULARIO SIN TÍTULO',
          version: 'v1',
          descripcion: 'Sin descripción',
          secciones: [
            {
              titulo: 'Sección sin titulo',
              subtitulo: 'Sin subtítulo ',
              preguntas: [],
            },
          ],
        },
      ]
      this.$http.post('/recursos/formulario', DATA).then(res => {
        this.$router.push({ name: 'nuevo-formulario-index', params: { idFormulario: res.data.id } })
      })
    },

  },
}
</script>
<style lang="scss">

</style>
